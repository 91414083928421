
import { Options, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { namespace } from "s-vuex-class";
import isEmpty from "lodash/isEmpty";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import GuestLayout from "@/layout/GuestLayout.vue";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";
import { isPlatform } from "@ionic/vue";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";

const AuthModule = namespace("auth");

@Options({
  components: {
    SchoolLogo,
    GuestLayout,
    Validation,
  },
  validations: {
    username: { required },
    password: { required },
  },
})
export default class Login extends Vue {
  public name = "Login";

  public v$ = useVuelidate();

  public username = "";
  public password = "";
  public remember = 0;
  public showPassword = false;

  public get version(): string | null {
    if (!isPlatform("desktop")) {
      const { VERSION } = getBuildInfo();
      const APP_VERSION = process.env.VUE_APP_VERSION;
      return `Version: ${APP_VERSION || VERSION}`;
    }
    return null;
  }

  @AuthModule.Action("signIn")
  public signIn: any;

  @AuthModule.Action("setLoading")
  public setLoadingAction: any;

  @AuthModule.Getter("getError")
  public getError: any;

  @AuthModule.Getter("getIsLoading")
  public loading: any;

  public created(): void {
    const storedRemember = localStorage.getItem("remember");
    this.remember = storedRemember ? parseInt(storedRemember) : 0;
  }

  public mounted(): void {
    this.setLoadingAction(false);
  }

  public onRememberMeChange(value: number): void {
    localStorage.setItem("remember", value.toString());
  }

  public onSubmit(): void {
    //@ts-ignore
    this.v$.$touch();

    //@ts-ignore
    if (this.v$.$invalid) {
      return;
    }

    this.signIn({
      username: this.username,
      password: this.password,
      authClient: "DRIVE_BUZZ_WEB",
    });
  }

  public get hasError(): boolean {
    return !isEmpty(this.getError);
  }

  public get drivingSchoolId(): number | null {
    return this.$route.query?.drivingSchoolId ? Number(this.$route.query.drivingSchoolId) : null;
  }
}
