
import { Options, Prop, Vue } from "vue-property-decorator";
import Image from "@/directives/Image";
// @ts-ignore
import instructorPlaceholder from "@/assets/fsc_logo.png";

@Options({
  directives: {
    Media: new Image(),
  },
})
export default class SchoolLogo extends Vue {
  public name = "SchoolLogo";

  @Prop({ default: 0 })
  public drivingSchoolId!: number;

  @Prop({ type: String, default: "avatar-lg" })
  public styleClass!: number;

  private isLoading = false;

  private get avatarUrl(): string {
    return `driving-schools/logo/${this.drivingSchoolId}`;
  }

  private handleError(): any {
    const avatarEl = this.$refs.logo as HTMLImageElement;
    avatarEl.src = instructorPlaceholder;
    avatarEl.alt = "Profile";
    this.isLoading = false;
  }

  private get hasId(): boolean {
    return Boolean(this.drivingSchoolId);
  }

  private onLoading() {
    this.isLoading = true;
  }

  private onSuccess() {
    this.isLoading = false;
  }
}
