
import { defineComponent } from "vue";

export default defineComponent({
  name: "DriveBuzzLogo",
  props: {
    to: {
      type: String,
      default: () => "/",
    },
    component: {
      type: String,
      default: () => "div",
    },
  },
});
